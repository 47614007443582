import { serialize, deserialize } from "superjson";
import { env } from "@/env.mjs";

export const transformer = {
  serialize,
  deserialize,
};

export function getUrl() {
  return `https://${env.NEXT_PUBLIC_TRPC_API_DOMAIN_NAME}`;
}
